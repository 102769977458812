import { NEXT_INSTANCE } from '@/lib/api'
import { create } from 'zustand'
import useAuth from './auth'

const useFpd = create((set, get) => ({
  logEvent: async (data) => {
    try {
      const res = await NEXT_INSTANCE.post(`/next-api/auth/fpd/event`, data)
      return res.data
    } catch (err) {
      console.error(err?.response?.data)
      return err?.response?.data
    }
  },
  answerQuiz: async (data) => {
    try {
      const res = await NEXT_INSTANCE.post(`/next-api/auth/fpd/quiz`, data)
      return res.data
    } catch (err) {
      console.error(err?.response?.data)
      return err?.response?.data
    }
  },
  getArticleQuiz: async (postID) => {
    try {
      const res = await NEXT_INSTANCE.get(
        `/next-api/auth/fpd/quiz?post_id=${postID}`
      )
      return res.data
    } catch (err) {
      console.error(err?.response?.data)
      return err?.response?.data
    }
  },
  redeemReward: async (data) => {
    try {
      const res = await NEXT_INSTANCE.post(`/next-api/auth/fpd/reward`, data)
      return res.data
    } catch (err) {
      console.error(err?.response?.data)
      return err?.response?.data
    }
  },
  sortRewardByUserBadge: (rewards) => {
    const userBadgeIds = useAuth.getState().userMe?.fpd_bfan_badges
    if (!userBadgeIds) return rewards
    return rewards.sort((a, b) => {
      // Handle case where for_badge is an array
      const aBadges = Array.isArray(a.for_badge) ? a.for_badge : [a.for_badge]
      const bBadges = Array.isArray(b.for_badge) ? b.for_badge : [b.for_badge]

      // Find the highest priority badge for each reward
      const aHighestPriority = aBadges.reduce((highest, badgeId) => {
        const index = userBadgeIds.findIndex(
          (badge) => badge.badge_id == badgeId
        )
        return index > highest ? index : highest
      }, -1)

      const bHighestPriority = bBadges.reduce((highest, badgeId) => {
        const index = userBadgeIds.findIndex(
          (badge) => badge.badge_id == badgeId
        )
        return index > highest ? index : highest
      }, -1)

      // Sort by highest priority badge
      return bHighestPriority - aHighestPriority
    })
  },

  applyReferralCode: async (referral_code = null, token = null) => {
    if (!referral_code || !token) {
      return
    }
    try {
      const res = await NEXT_INSTANCE.post(
        `/next-api/auth/fpd/referral/apply`,
        {
          referral_code,
          token,
        }
      )
      return res.data
    } catch (err) {
      console.error(err?.response?.data)
      return err?.response?.data
    }
  },
}))

export default useFpd
